import request from "@/utils/request";

// 删除子账号
export function deleteSubAccount(data) {
    return request({
        url: '/usercenter/subAccountInfo/deleteSubAccount',
        method: 'post',
        data
    })
}

//子账号数查询
export function getAccountNum(data) {
    return request({
        url: '/usercenter/subAccountInfo/getAccountNum',
        method: 'post',
        data
    })
}
//子账号保存（新增、修改、状态更改)
export function saveSubAccount(data) {
    return request({
        url: '/usercenter/subAccountInfo/saveSubAccount',
        method: 'post',
        data
    })
}
//展示子账号
export function listSubAccount(data) {
    return request({
        url: '/usercenter/subAccountInfo/listSubAccount',
        method: 'post',
        data
    })
}
