import { userItem, list, qrcode, rechargeQuery } from '@/api/benefit'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    // 获取用户现有开通的项目的情况
    userItem({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            userItem(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //获取某个项目的套餐
    list({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            list(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //生成支付二维码
    qrcode({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            qrcode(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //查询支付结果
    rechargeQuery({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            rechargeQuery(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

}

export default {
    namespaced: true,
    state,
    actions
}
