import { getSupplierAuthInfo ,saveShopAuthInfo,saveSupplierAuthInfo} from '@/api/authCenter'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    //上传到oss
    getSupplierAuthInfo({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getSupplierAuthInfo(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //保存商家认证信息
    saveShopAuthInfo({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            saveShopAuthInfo(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
     //保存供应商认证信息
     saveSupplierAuthInfo({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            saveSupplierAuthInfo(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

}

export default {
    namespaced: true,
    state,
    actions
}
