import request from "@/utils/request";


//获取供应商认证信息
export function getSupplierAuthInfo(data) {
    return request({
        url: '/usercenter/authCenter/get/supplierAuthInfo',
        method: 'post',
        data
    })
}

//保存商家认证信息
export function saveShopAuthInfo(data) {
    return request({
        url: '/usercenter/authCenter/save/shopAuthInfo',
        method: 'post',
        data
    })
}

//保存供应商认证信息
export function saveSupplierAuthInfo(data) {
    return request({
        url: '/usercenter/authCenter/save/supplierAuthInfo',
        method: 'post',
        data
    })
}
