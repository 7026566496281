import request from "@/utils/request";


//获取某个项目的套餐
export function list(data) {
    return request({
        url: '/usercenter/siteLetter/list',
        method: 'post',
        data
    })
}
//获取详情
export function getDetail(data) {
    return request({
        url: '/usercenter/siteLetter/getDetail',
        method: 'post',
        data
    })
}