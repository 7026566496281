import { list, getDetail } from '@/api/siteLetter'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    //获取站内信
    list({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            list(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //获取详情
    getDetail({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getDetail(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

}

export default {
    namespaced: true,
    state,
    actions
}
