import request from "@/utils/request";

// 获取用户现有开通的项目的情况
export function userItem(data) {
    return request({
        url: '/usercenter/benefit/userItem',
        method: 'post',
        data
    })
}

//获取某个项目的套餐
export function list(data) {
    return request({
        url: '/usercenter/benefit/list',
        method: 'post',
        data
    })
}
//生成支付二维码
export function qrcode(data) {
    return request({
        url: '/usercenter/benefit/qrcode',
        method: 'post',
        data
    })
}
//查询支付结果
export function rechargeQuery(data) {
    return request({
        url: '/usercenter/benefit/recharge/query',
        method: 'post',
        data
    })
}
