import request from "@/utils/request";


//上传到oss
export function uploadImageToOSS(data) {
    return request({
        url: '/usercenter/common/uploadImageToOSS',
        method: 'post',
        data
    })
}

//获取所有品类
export function initCategory(data) {
    return request({
        url: '/usercenter/common/initCategory',
        method: 'post',
        data
    })
}