import {  deleteSubAccount,getAccountNum,saveSubAccount,listSubAccount } from '@/api/subAccountInfo'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    //展示子账号
    listSubAccount({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            listSubAccount(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //删除子账号
    deleteSubAccount({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            deleteSubAccount(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //子账号数查询
    getAccountNum({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getAccountNum(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //子账号保存（新增、修改、状态更改)
    saveSubAccount({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            saveSubAccount(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions
}
